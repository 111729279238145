.App {
  text-align: center;
}

.App-logo {
  background-color: #1b1d23;
  height: 40vmin;
  pointer-events: none;

  border-radius: 25px;
  border: 6px solid #FAA425;
  /* outline: 1px solid darkblue; */
  box-shadow: 0 0 1pt 1pt #1b1d23;
  padding: 20px;
  float: left;
  /* 
  margin-left: 1px;
  margin-right: 50px;*/
}

.App-header {
  /* background-color: #FAA425; */
  background: linear-gradient(to bottom, #FAA425 0%, #FAA425 40.0%, #1b1d23 0, #1b1d23 56.0%, #FAA425 0);
  /* background-size: 100% 20px; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #1b1d23;
}

.App-link {
  color: #61dafb;
}

.App-content {
  font-size: small;
  width: 90%;
}

.App-bottom {
  font-size: x-small;
  position: absolute;
  bottom: 30px;
  width: 100%;
}